import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pagination"
    }}>{`Pagination`}</h1>
    <p><inlineCode parentName="p">{`import { Pagination } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`This is a `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/higher-order-components.html"
      }}>{`higher-order component`}</a>{` built on top of the `}<a parentName="p" {...{
        "href": "https://github.com/ultimate-pagination/react-ultimate-pagination"
      }}>{`react-ultimate-pagination`}</a>{` library.`}</p>
    <p>{`You can find an example along with the possible parameters listed below. For more information, see the library documentation.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`currentPage:`}</strong>{` number - current page number`}</li>
      <li parentName="ul"><strong parentName="li">{`totalPages:`}</strong>{` number - total number of pages`}</li>
      <li parentName="ul"><strong parentName="li">{`boundaryPagesRange:`}</strong>{` number, optional, default: 1 - number of always visible pages at the beginning and end`}</li>
      <li parentName="ul"><strong parentName="li">{`siblingPagesRange:`}</strong>{` number, optional, default: 1 - number of always visible pages before and after the current one`}</li>
      <li parentName="ul"><strong parentName="li">{`hideEllipsis:`}</strong>{` bool, optional, default: false - boolean flag to hide ellipsis`}</li>
      <li parentName="ul"><strong parentName="li">{`hidePreviousAndNextPageLinks:`}</strong>{` bool, optional, default: false - boolean flag to hide previous and next page links`}</li>
      <li parentName="ul"><strong parentName="li">{`hideFirstAndLastPageLinks:`}</strong>{` bool, optional, default: false - boolean flag to hide first and last page links`}</li>
      <li parentName="ul"><strong parentName="li">{`onChange:`}</strong>{` function - callback that will be called with new page when it should be changed by user interaction (optional)`}</li>
    </ul>
    <h2 {...{
      "id": "code-example"
    }}>{`Code Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { Component } from 'react'
import { Pagination } from '@foodsby/nutrient'
const pagesize = 5

export default class Demo extends Component {
  selectData = (page, pageSize) => {
    const offset = (page - 1) * pageSize
    return this.props.data.slice(offset, offset + pageSize)
  }

  handleChange = page => {
    this.setState({
      currentPage: page,
      data: this.selectData(page, pagesize)
    })
  }

  state = {
    currentPage: 1,
    totalPages: Math.ceil(this.props.data.length / 5),
    data: this.selectData(1, pagesize)
  }

  render() {
    const { currentPage, totalPages } = this.state
    return (
      <>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Occupation</th>
              <th>City</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map(({ id, name, occupation, city }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{occupation}</td>
                <td>{city}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onChange={this.handleChange}
          style={{ marginTop: '16px' }}
        />
      </>
    )
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      